import { initReactI18next } from 'react-i18next';
import i18n, { TOptions } from 'i18next';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    react: {
      useSuspense: false,
    },
    debug: process.env.NODE_ENV !== 'production',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    ns: [
      'common',
      'buttons',
      'filters',
      'ptoRequests',
      'tooltips',
      'sideBar',
      'navBar',
      'modals',
      'ptoInfo',
      'members',
      'roles',
      'notifications',
      'holidays',
      'notFound',
      'reports',
      'subject',
      'category',
      'report',
      'dropdowns',
      'departments',
      'reportTypes',
      'profile',
    ],
  });

const t = i18n.t.bind(i18n);

/**
 * Translate function that can be used outside react components and hooks
 * @param translationKey for example: customerHomepage:search.title
 * @param options when we need to pass a js value to translation
 * @returns text from key
 */
export const translate = (translationKey: string, options?: TOptions): string => t(translationKey, options);

export default i18n;
